import { Link } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import CampaignIcon from '@mui/icons-material/Campaign';
import Close from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { makeStyles } from '@mui/styles';
import { Announcement } from '@procore-oss/backstage-plugin-announcements-common';
import {
  announcementsApiRef,
  useAnnouncements,
} from '@procore-oss/backstage-plugin-announcements-react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  // showing on top, as a block
  blockPositioning: {
    position: 'relative',
    top: 0,
  },
  // showing on top, as a floating alert
  floatingPositioning: {},
  icon: {
    fontSize: 20,
    color: '#030220',
  },
  bannerIcon: {
    fontSize: 25,
    marginRight: '0.5rem',
    color: '#030220',
    verticalAlign: 'middle',
  },
  commonStyle: {
    color: '#1d0638',
    textDecoration: 'none',
    fontWeight: 'bold',
  },

  excerptStyle: {
    color: '#1d0638',
    textDecoration: 'none',
    marginLeft: '8px',
  },
  content: {
    width: '100%',
    maxWidth: 'inherit',
    flexWrap: 'nowrap',
    // backgroundColor: theme.palette.banner.closeButtonColor,
    // PO ask to use custom color and this will going to change once theme is intgate in the application in upcoming ticktes
    backgroundColor: '#fef04a',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.banner.text,
    '& a': {
      color: theme.palette.banner.link,
    },
  },
}));

type AnnouncementBannerProps = {
  announcement: Announcement;
  variant?: 'block' | 'floating';
};

const AnnouncementBanner = (props: AnnouncementBannerProps) => {
  const classes = useStyles();
  const announcementsApi = useApi(announcementsApiRef);
  const [bannerOpen, setBannerOpen] = useState(true);
  const variant = props.variant || 'block';
  const announcement = props.announcement;

  const handleClick = () => {
    announcementsApi.markLastSeenDate(
      DateTime.fromISO(announcement.created_at),
    );
    setBannerOpen(false);
  };

  const message = (
    <>
      <CampaignIcon className={classes.bannerIcon} />
      <Link
        key={announcement.id}
        to={`announcements/view/${announcement.id}`}
        className={classes.commonStyle}
      >
        {announcement.title}
      </Link>

      <span className={classes.excerptStyle}>
        &nbsp;– {announcement.excerpt}
      </span>
    </>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={bannerOpen}
      className={
        variant === 'block'
          ? classes.blockPositioning
          : classes.floatingPositioning
      }
    >
      <SnackbarContent
        className={classes.content}
        message={message}
        action={[
          <IconButton
            key="dismiss"
            title="Mark as seen"
            color="inherit"
            onClick={handleClick}
          >
            <Close className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

type NewAnnouncementBannerProps = {
  variant?: 'block' | 'floating';
  max?: number;
  category?: string;
};

export const NewAnnouncementBanner = (props: NewAnnouncementBannerProps) => {
  const announcementsApi = useApi(announcementsApiRef);

  const { announcements, loading, error } = useAnnouncements({
    max: props.max || 1,
    category: props.category,
  });
  const lastSeen = announcementsApi.lastSeenDate();

  if (loading) {
    return null;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (announcements.count === 0) {
    return null;
  }

  const unseenAnnouncements = announcements.results.filter(announcement => {
    return lastSeen < DateTime.fromISO(announcement.created_at);
  });

  if (unseenAnnouncements?.length === 0) {
    return null;
  }

  return (
    <>
      {unseenAnnouncements.map(announcement => (
        <AnnouncementBanner
          key={announcement.id}
          announcement={announcement}
          variant={props.variant}
        />
      ))}
    </>
  );
};
