import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  identityApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import {
  catalogImportApiRef,
  CatalogImportClient,
} from '@backstage/plugin-catalog-import';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { OAuth2 } from '@backstage/core-app-api';

export const cloudIdpAuthApiRef: ApiRef<
  OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'auth.cloudidp-provider',
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: catalogImportApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      scmAuthApi: scmAuthApiRef,
      identityApi: identityApiRef,
      scmIntegrationsApi: scmIntegrationsApiRef,
      catalogApi: catalogApiRef,
      configApi: configApiRef,
    },
    factory: ({
      discoveryApi,
      scmAuthApi,
      identityApi,
      scmIntegrationsApi,
      catalogApi,
      configApi,
    }) =>
      new CatalogImportClient({
        discoveryApi,
        scmAuthApi,
        scmIntegrationsApi,
        identityApi,
        catalogApi,
        configApi,
      }),
  }),

  createApiFactory({
    api: cloudIdpAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'cloudidp-provider',
          title: 'CARIAD CloudIDP Provider',
          icon: () => null,
        },
        environment: configApi.getOptionalString('auth.environment'),
        defaultScopes: ['openid', 'profile', 'email'],
        popupOptions: {
          // optional, used to customize login in popup size
          size: {
            height: 600,
            width: 600,
          },
        },
      }),
  }),
];
