import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  configApiRef,
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { TechDocsClient, TechDocsMetadata } from '@backstage/plugin-techdocs';
import React from 'react';

export type TechDocsMetadataWithTime = TechDocsMetadata & {
  build_timestamp: number;
};

function useTechDocsMetadata(techdocRef: CompoundEntityRef) {
  const configApi = useApi(configApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const [metadata, setMetadata] = React.useState<TechDocsMetadataWithTime>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  const fetchTechDocsMetadata = React.useCallback(
    async (ref: CompoundEntityRef) => {
      setIsLoading(true);
      setError(undefined);
      try {
        const techdocsClient = new TechDocsClient({
          configApi: configApi,
          discoveryApi: discoveryApi,
          fetchApi: fetchApi,
        });
        const techDocsMetadata = await techdocsClient.getTechDocsMetadata(ref);
        if (!techDocsMetadata) {
          throw new Error('No techdoc metadata found');
        }
        setMetadata(techDocsMetadata as TechDocsMetadataWithTime);
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [configApi, discoveryApi, fetchApi],
  );

  React.useEffect(() => {
    fetchTechDocsMetadata(techdocRef);
  }, [fetchTechDocsMetadata, techdocRef]);

  return { metadata, isLoading, error };
}

export default useTechDocsMetadata;
