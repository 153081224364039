import React, { useState, useEffect } from 'react';
import { Page, Link, Content } from '@backstage/core-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { XelerateHomeLogo } from './images/XelerateHomeLogo';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NewAnnouncementBanner } from '../announcements/NewAnnouncementBanner';

const useStyles = makeStyles({
  homePage: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#000000',
    display: 'grid',
    gridTemplateRows: '3fr auto 1fr',
    height: '100vh',
  },
  cariadBackstageBox: {
    alignItems: 'center',
    columnGap: '3rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    marginLeft: '8.3rem',
    marginBottom: '60px',
    rowGap: '2rem',
  },
  cariadBackstageLogo: {
    maxWidth: '75vw',
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gridRowGap: '2rem',
    marginLeft: '8rem',
    marginBottom: '0px',
    // justifyContent: 'center',
  },
  button: {
    backgroundColor: 'rgba(61, 50, 205, 0.6)',
    border: '1px solid #442EE0',
    borderRadius: '2rem',
    color: '#FFFFFF',
    fontSize: '1.125rem',
    fontWeight: 700,
    textTransform: 'none',
    padding: '1rem 1.75rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
});

type HomeLinkProps = {
  title: string;
  url: string;
};

const homeCards: HomeLinkProps[] = [
  {
    title: 'What is XELERATE?',
    url: '/catalog/default/component/xelerate/docs',
  },
  {
    title: 'Explore the Software Catalog',
    url: '/catalog',
  },
  {
    title: 'Add Your Project',
    url: '/catalog-import',
  },
];

export const HomePage = () => {
  const classes = useStyles();
  const [imageURLToUse, setImageURLToUse] = useState<URL | null>(null);

  // Media queries for breakpoints
  const isSmallScreen = useMediaQuery('(max-width: 1365px)');
  const isMediumScreen = useMediaQuery(
    '(max-width: 1919px) and (min-width: 1366px)',
  );
  const isLargeScreen = useMediaQuery(
    '(max-width: 2559px) and (min-width: 1920px)',
  );
  const isXLScreen = useMediaQuery('(min-width: 2560px)');

  // Set up responsive background images. Large is the default
  useEffect(() => {
    if (isSmallScreen) {
      setImageURLToUse(
        new URL('../../../public/welcome-bg-small.webp', import.meta.url),
      );
    } else if (isMediumScreen) {
      setImageURLToUse(
        new URL('../../../public/welcome-bg-medium.webp', import.meta.url),
      );
    } else if (isLargeScreen) {
      setImageURLToUse(
        new URL('../../../public/welcome-bg-large.webp', import.meta.url),
      );
    } else {
      setImageURLToUse(
        new URL('../../../public/welcome-bg-xl.webp', import.meta.url),
      );
    }
  }, [isSmallScreen, isMediumScreen, isLargeScreen, isXLScreen]);

  return (
    <Page themeId="home">
      <Helmet titleTemplate="%s">
        <title>Home | CARIAD Xelerate Backstage</title>
      </Helmet>

      <Content noPadding>
        <Box
          className={classes.homePage}
          style={{
            ...(imageURLToUse
              ? { backgroundImage: `url(${imageURLToUse})` }
              : {}),
          }}
        >
          <Box>
            <NewAnnouncementBanner />
          </Box>

          <div>
            <Box className={classes.cariadBackstageBox}>
              <XelerateHomeLogo />
            </Box>
            <Box className={classes.buttonContainer}>
              {homeCards.map((link, index) => (
                <Box key={index} m={1}>
                  <Link to={link.url} style={{ textDecoration: 'none' }}>
                    <Button className={classes.button}>{link.title}</Button>
                  </Link>
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </Content>
    </Page>
  );
};
