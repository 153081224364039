import React from 'react';

export const XelerateHomeLogo = () => (
  <svg
    width="797"
    height="175"
    viewBox="0 0 797 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.165 171.61H156.383L79.7171 87.6253L156.383 3.64041H184.165L107.498 87.6253L184.165 171.61Z"
      stroke="#1EEF97"
      strokeWidth="5.73"
      strokeMiterlimit="8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H27.7143L104.197 87.5L27.7143 175H0L76.4823 87.5L0 0Z"
      fill="#1EEF97"
    />
    <path
      d="M204 49H259.983V57.6111H214.169V84.2778H254.396V92.8889H214.169V120.389H260.765V129H204V49Z"
      fill="white"
    />
    <path
      d="M292.045 49H302.325V120.056H347.022V129H292.045V49Z"
      fill="white"
    />
    <path
      d="M377.034 49H433.017V57.6111H387.203V84.2778H427.43V92.8889H387.203V120.389H433.799V129H377.034V49Z"
      fill="white"
    />
    <path
      d="M503.965 49C510.968 49 516.797 51.0185 521.453 55.0556C526.146 59.0926 528.493 64.5556 528.493 71.4444C528.493 77.9259 526.407 83.1667 522.235 87.1667C518.101 91.1667 512.83 93.4074 506.424 93.8889L528.493 127.333V129H517.989L495.473 94.1111H475.192V129H465.079V49H503.965ZM475.192 85.5556H502.513C507.318 85.5556 511.173 84.3519 514.078 81.9444C516.983 79.5 518.436 76.0556 518.436 71.6111C518.436 67.1667 516.983 63.7222 514.078 61.2778C511.173 58.8333 507.318 57.6111 502.513 57.6111H475.192V85.5556Z"
      fill="white"
    />
    <path
      d="M583.843 49H597.028L626.416 127.333V129H616.192L607.699 106H572.892L564.455 129H554.51V127.333L583.843 49ZM575.853 97.2222H604.738L590.771 59.2222H589.877L575.853 97.2222Z"
      fill="white"
    />
    <path
      d="M641.951 49H710.337V57.8333H681.284V129H671.004V57.8333H641.951V49Z"
      fill="white"
    />
    <path
      d="M740.235 49H796.218V57.6111H750.404V84.2778H790.631V92.8889H750.404V120.389H797V129H740.235V49Z"
      fill="white"
    />
  </svg>
);
