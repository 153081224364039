import React from 'react';
import { IconComponent } from '@backstage/core-plugin-api';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export type IconProps = {
  lightMode: boolean;
};

export const StackOverflowIcon = (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 105 125">
    <g fill="none" fillRule="evenodd">
      <polygon
        fill={props.lightMode ? '#fff' : '#BBBBBB'}
        points="88 80 99 80 99 124 0 124 0 80 11 80 11 113 88 113"
      />
      <path
        fill={props.lightMode ? '#fff' : '#F58025'}
        fillRule="nonzero"
        d="M22.9878906,76.73 L77.0128906,88.085 L79.2838906,77.285 L25.2588906,65.925 L22.9878906,76.73 Z M30.1368906,50.861 L80.1828906,74.169 L84.8448906,64.16 L34.7978906,40.852 L30.1368906,50.861 Z M43.9848906,26.308 L86.4128906,61.639 L93.4788906,53.154 L51.0508906,17.824 L43.9848906,26.308 Z M71.3718906,0.192 L62.5118906,6.782 L95.4598906,51.082 L104.319891,44.493 L71.3718906,0.192 Z M22,102 L77,102 L77,91 L22,91 L22,102 Z"
      />
    </g>
  </SvgIcon>
);

export const AzureIcon = (props: SvgIconProps) => (
  <SvgIcon width="150" height="150" viewBox="0 0 96 96" {...props}>
    <defs>
      <linearGradient
        id="e399c19f-b68f-429d-b176-18c2117ff73c"
        x1="-1032.172"
        x2="-1059.213"
        y1="145.312"
        y2="65.426"
        gradientTransform="matrix(1 0 0 -1 1075 158)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#114a8b" />
        <stop offset="1" stopColor="#0669bc" />
      </linearGradient>
      <linearGradient
        id="ac2a6fc2-ca48-4327-9a3c-d4dcc3256e15"
        x1="-1023.725"
        x2="-1029.98"
        y1="108.083"
        y2="105.968"
        gradientTransform="matrix(1 0 0 -1 1075 158)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopOpacity=".3" />
        <stop offset=".071" stopOpacity=".2" />
        <stop offset=".321" stopOpacity=".1" />
        <stop offset=".623" stopOpacity=".05" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="a7fee970-a784-4bb1-af8d-63d18e5f7db9"
        x1="-1027.165"
        x2="-997.482"
        y1="147.642"
        y2="68.561"
        gradientTransform="matrix(1 0 0 -1 1075 158)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3ccbf4" />
        <stop offset="1" stopColor="#2892df" />
      </linearGradient>
    </defs>
    <path
      fill="url(#e399c19f-b68f-429d-b176-18c2117ff73c)"
      d="M33.338 6.544h26.038l-27.03 80.087a4.152 4.152 0 0 1-3.933 2.824H8.149a4.145 4.145 0 0 1-3.928-5.47L29.404 9.368a4.152 4.152 0 0 1 3.934-2.825z"
    />
    <path
      fill="#0078d4"
      d="M71.175 60.261h-41.29a1.911 1.911 0 0 0-1.305 3.309l26.532 24.764a4.171 4.171 0 0 0 2.846 1.121h23.38z"
    />
    <path
      fill="url(#ac2a6fc2-ca48-4327-9a3c-d4dcc3256e15)"
      d="M33.338 6.544a4.118 4.118 0 0 0-3.943 2.879L4.252 83.917a4.14 4.14 0 0 0 3.908 5.538h20.787a4.443 4.443 0 0 0 3.41-2.9l5.014-14.777 17.91 16.705a4.237 4.237 0 0 0 2.666.972H81.24L71.024 60.261l-29.781.007L59.47 6.544z"
    />
    <path
      fill="url(#a7fee970-a784-4bb1-af8d-63d18e5f7db9)"
      d="M66.595 9.364a4.145 4.145 0 0 0-3.928-2.82H33.648a4.146 4.146 0 0 1 3.928 2.82l25.184 74.62a4.146 4.146 0 0 1-3.928 5.472h29.02a4.146 4.146 0 0 0 3.927-5.472z"
    />
  </SvgIcon>
);

export const AzureDevOpsIcon = (props: SvgIconProps) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <defs>
      <linearGradient
        id="ba420277-700e-42cc-9de9-5388a5c16e54"
        x1="9"
        y1="16.97"
        x2="9"
        y2="1.03"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#0078d4" />
        <stop offset="0.16" stopColor="#1380da" />
        <stop offset="0.53" stopColor="#3c91e5" />
        <stop offset="0.82" stopColor="#559cec" />
        <stop offset="1" stopColor="#5ea0ef" />
      </linearGradient>
    </defs>
    <title>Icon-devops-261</title>
    <path
      id="a91f0ca4-8fb7-4019-9c09-0a52e2c05754"
      d="M17,4v9.74l-4,3.28-6.2-2.26V17L3.29,12.41l10.23.8V4.44Zm-3.41.49L7.85,1V3.29L2.58,4.84,1,6.87v4.61l2.26,1V6.57Z"
      fill="url(#ba420277-700e-42cc-9de9-5388a5c16e54)"
    />
  </SvgIcon>
);

export const SlackLogoIcon = (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 2447.6 2452.5">
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="m897.4 0c-135.3.1-244.8 109.9-244.7 245.2-.1 135.3 109.5 245.1 244.8 245.2h244.8v-245.1c.1-135.3-109.5-245.1-244.9-245.3.1 0 .1 0 0 0m0 654h-652.6c-135.3.1-244.9 109.9-244.8 245.2-.2 135.3 109.4 245.1 244.7 245.3h652.7c135.3-.1 244.9-109.9 244.8-245.2.1-135.4-109.5-245.2-244.8-245.3z"
        fill={props.lightMode ? '#fff' : '#36c5f0'}
      />
      <path
        d="m2447.6 899.2c.1-135.3-109.5-245.1-244.8-245.2-135.3.1-244.9 109.9-244.8 245.2v245.3h244.8c135.3-.1 244.9-109.9 244.8-245.3zm-652.7 0v-654c.1-135.2-109.4-245-244.7-245.2-135.3.1-244.9 109.9-244.8 245.2v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.3z"
        fill={props.lightMode ? '#fff' : '#2eb67d'}
      />
      <path
        d="m1550.1 2452.5c135.3-.1 244.9-109.9 244.8-245.2.1-135.3-109.5-245.1-244.8-245.2h-244.8v245.2c-.1 135.2 109.5 245 244.8 245.2zm0-654.1h652.7c135.3-.1 244.9-109.9 244.8-245.2.2-135.3-109.4-245.1-244.7-245.3h-652.7c-135.3.1-244.9 109.9-244.8 245.2-.1 135.4 109.4 245.2 244.7 245.3z"
        fill={props.lightMode ? '#fff' : '#ecb22e'}
      />
      <path
        d="m0 1553.2c-.1 135.3 109.5 245.1 244.8 245.2 135.3-.1 244.9-109.9 244.8-245.2v-245.2h-244.8c-135.3.1-244.9 109.9-244.8 245.2zm652.7 0v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.2v-653.9c.2-135.3-109.4-245.1-244.7-245.3-135.4 0-244.9 109.8-244.8 245.1 0 0 0 .1 0 0"
        fill={props.lightMode ? '#fff' : '#e01e5a'}
      />
    </g>
  </SvgIcon>
);

export const MenuBookIcon = (props: IconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#fff"
    width="24px"
    height="24px"
  >
    <path
      fill={props.lightMode ? '#fff' : ''}
      d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"
    />
  </SvgIcon>
);

export const SupportIcon = (props: IconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    fill="none"
    width="48px"
    height="48px"
  >
    <path fill="none" d="M0 0h48v48H0z" />
    <path
      fill={props.lightMode ? '#fff' : ''}
      d="M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm2 34h-4v-4h4v4zm4.13-15.49-1.79 1.84C26.9 25.79 26 27 26 30h-4v-1c0-2.21.9-4.21 2.34-5.66l2.49-2.52C27.55 20.1 28 19.1 28 18c0-2.21-1.79-4-4-4s-4 1.79-4 4h-4c0-4.42 3.58-8 8-8s8 3.58 8 8c0 1.76-.71 3.35-1.87 4.51z"
    />
  </SvgIcon>
);

export const EmailIcon = (props: IconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#fff"
    width="24px"
    height="24px"
  >
    <path
      fill={props.lightMode ? '#fff' : ''}
      d="M12 13.1L3.5 6h17L12 13.1zm0 2.2L3.5 8v10h17V8L12 15.3z"
    />
  </SvgIcon>
);

export const icons = {
  stackOverflow: StackOverflowIcon as IconComponent,
  azure: AzureIcon as IconComponent,
  azuredevops: AzureDevOpsIcon as IconComponent,
  slack: SlackLogoIcon as IconComponent,
  menuBook: MenuBookIcon as IconComponent,
};
