import React from 'react';
import {
  EmailIcon,
  MenuBookIcon,
  SlackLogoIcon,
  StackOverflowIcon,
  SupportIcon,
} from '../Icons/icons';

export const sidebarPopupMenuOptions = [
  {
    icon: () => <MenuBookIcon lightMode />,
    label: 'XELERATE Documentation',
    external: false,
    to: '/catalog/default/component/xelerate/docs',
  },
  {
    icon: () => <SupportIcon lightMode />,
    label: 'Create a support ticket',
    external: true,
    to: 'https://devstack.vwgroup.com/jira/servicedesk/customer/portal/251',
  },
  {
    icon: () => <EmailIcon lightMode />,
    label: 'Contact us via mail',
    external: true,
    to: 'mailto:xelerate@cariad.technology?subject=Support%20XELERATE',
  },
  {
    icon: () => <SlackLogoIcon lightMode />,
    label: 'Reach out in Slack',
    external: true,
    to: 'https://cariad.enterprise.slack.com/archives/C06UA5E3TK8',
  },
  {
    icon: () => <StackOverflowIcon lightMode />,
    label: 'Join our SO community',
    external: true,
    to: 'https://stackoverflow.cariad.digital/communities/65',
  },
];
