import React, { useState, useEffect } from 'react';
import { SignInPageProps } from '@backstage/core-app-api';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from '@mui/material/Link';
import PolicyIcon from '@mui/icons-material/Policy';
import { useApi } from '@backstage/core-plugin-api';
import {
  Progress,
  SignInProviderConfig,
  UserIdentity,
} from '@backstage/core-components';
import { CariadXelerateSignInLogo } from './images/CariadXelerateSignInLogo';

// * Custom Styles
const useStyles = makeStyles(() => ({
  mainWrapper: {
    width: 'min-content',
  },
  cariadLogoWrapper: {
    width: '650px',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    width: 'calc(100% - 85px)',
  },
  subtitle: {
    fontWeight: '800',
    fontSize: '2.5rem',
    color: '#f9f9f9',
    '@media (max-width: 1600px)': {
      fontSize: '2rem',
    },
  },
  copy: {
    fontSize: '1.5rem',
    lineHeight: '3rem',
    color: '#f9f9f9',
    '@media (max-width: 1600px)': {
      fontSize: '1.25rem',
      lineHeight: '2rem',
    },
  },
  radioLabel: {
    color: '#f9f9f9',
    '& .v5-MuiFormControlLabel-label': {
      fontSize: '1.5rem',
      '@media (max-width: 1600px)': {
        fontSize: '1.25rem',
      },
    },
  },
  customRadio: {
    '& .v5-MuiSvgIcon-root': {
      fontSize: '2rem',
      '@media (max-width: 1600px)': {
        fontSize: '1.5rem',
      },
    },
    color: '#1EEF97',
    width: '50px',
    '&.Mui-checked': {
      color: '#1EEF97',
    },
  },
  base: {
    minWidth: '650px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    paddingLeft: '100px',
    '@media (max-width: 900px)': {
      paddingLeft: '50px',
    },
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  },
  legal: {
    position: 'absolute',
    left: '100px',
    bottom: '50px',
    '@media (max-width: 1200px)': {
      bottom: '25px',
    },
  },
  button: {
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#1EEF97',
    textTransform: 'none',
    color: '#373741',
    '&:hover': {
      backgroundColor: '#78F5C1',
    },
    '&:active': {
      backgroundColor: '#0DBC73',
    },
  },
}));

type SingleSignInPageProps = SignInPageProps & {
  provider: SignInProviderConfig;
  auto?: boolean;
};

type signinOpts = { showPopup: boolean };

// * Custom sign-in page component
const SignInPage: React.FC<SingleSignInPageProps> = ({
  provider,
  auto,
  onSignInSuccess,
}) => {
  const classes = useStyles();
  const authApi = useApi(provider.apiRef);

  const [error, setError] = useState<Error>();
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);
  const [imageURLToUse, setImageURLToUse] = useState<URL | null>(null);

  // * Media queries for breakpoints
  const isSmallScreen = useMediaQuery('(max-width: 1379px)');
  const isMediumScreen = useMediaQuery(
    '(max-width: 2579px) and (min-width: 1380px)',
  );
  const isLargeScreen = useMediaQuery('(min-width: 2580px)');

  // * Set up responsive background images. Large is the default
  useEffect(() => {
    if (isSmallScreen) {
      setImageURLToUse(
        new URL(
          '../../../public/xelerate-gradient-bg-full-small.webp',
          import.meta.url,
        ),
      );
    } else if (isMediumScreen) {
      setImageURLToUse(
        new URL(
          '../../../public/xelerate-gradient-bg-full.webp',
          import.meta.url,
        ),
      );
    } else {
      setImageURLToUse(
        new URL(
          '../../../public/xelerate-gradient-bg-full-large.webp',
          import.meta.url,
        ),
      );
    }
  }, [isSmallScreen, isMediumScreen, isLargeScreen]);

  // * Handler for signin process, user initiated or automatic.
  const handleSignin = React.useCallback(
    async ({ showPopup }: signinOpts) => {
      try {
        let identityResponse;
        // * Only if it is user initiated does the popup show
        if (showPopup) {
          identityResponse = await authApi.getBackstageIdentity({
            instantPopup: true,
          });
        } else {
          identityResponse = await authApi.getBackstageIdentity({
            optional: true,
          });
        }
        if (!identityResponse) {
          setShowLoginPage(true);
          return;
        }
        const profile = await authApi.getProfile();
        onSignInSuccess(
          UserIdentity.create({
            identity: identityResponse.identity,
            authApi,
            profile,
          }),
        );
      } catch (err: any) {
        setError(err);
        setShowLoginPage(true);
      }
    },
    [authApi, onSignInSuccess],
  );

  // * Do an initial check if to see if any logged-in session exists.
  // * If it does, SignIn Page isn't rendered, and the user is taken directly to home page.
  useEffect(() => {
    if (auto) {
      handleSignin({ showPopup: false });
    }
  }, [handleSignin, auto]);

  return showLoginPage ? (
    <ScopedCssBaseline
      className={classes.base}
      style={{
        ...(imageURLToUse ? { backgroundImage: `url(${imageURLToUse})` } : {}),
      }}
    >
      <article className={classes.mainWrapper}>
        <header className={classes.cariadLogoWrapper}>
          <CariadXelerateSignInLogo />
        </header>
        <section className={classes.formWrapper}>
          <Typography variant="h2" className={classes.subtitle}>
            Power to the developers, makers, and thinkers
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              handleSignin({ showPopup: true });
            }}
            className={classes.button}
            sx={{
              fontSize: {
                md: '1.25rem',
                lg: '1.5rem',
              },
            }}
          >
            {provider.message}
          </Button>
          {error && error.name !== 'PopupRejectedError' && (
            <Typography variant="body1" color="#EE5340">
              {`Something went wrong during your authentication: ${error.message}`}
            </Typography>
          )}
        </section>
      </article>
      <Link
        href="https://cariad.technology/de/en/legal-notice.html"
        variant="button"
        className={classes.legal}
        target="_blank"
        aria-label="Developer Experience Portal"
      >
        <Button
          variant="text"
          startIcon={<PolicyIcon style={{ fontSize: 30 }} />}
          sx={{
            color: '#f9f9f9',
            fontSize: '1rem',
            textTransform: 'none',
          }}
        >
          Legal Notice
        </Button>
      </Link>
    </ScopedCssBaseline>
  ) : (
    <Progress />
  );
};

export default SignInPage;
