import React from 'react';
import {
  SettingsLayout,
  UserSettingsGeneral,
  // UserSettingsFeatureFlags
} from '@backstage/plugin-user-settings';

export const settingsPage = (
  <SettingsLayout>
    <SettingsLayout.Route path="general" title="General">
      <UserSettingsGeneral />
    </SettingsLayout.Route>
    {/* <SettingsLayout.Route path="feature-flags" title="Feature Flags">
      <UserSettingsFeatureFlags />
    </SettingsLayout.Route> */}
  </SettingsLayout>
);
